import React, { FC, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { useQuery } from 'react-query';
import useLanguage from '../../hooks/useLanguage';
import QueryCacheName from '../../types/QueryCacheName.enum';
import { getDownloadDocsContent } from '../../services/Content';
import PolicyContentType from './types/PolicyContentType.enum';
import PoliciesTable from './PoliciesTable';
import DownloadDocsContent from './types/DownloadDocsContent.interface';
import { GetDownloadDocsContentParams } from './types/GetDownloadDocsContentParams.interface';
import useRole from '../../hooks/useRole';

const initContent: DownloadDocsContent[] = [
  {
    title: '',
    files: [
      {
        url: '',
        filename: ``,
        fileDesc: ' ',
        filedate: '',
      },
    ],
    category: '',
  },
];

const Policies: FC = () => {
  const lang = useLanguage();
  const { roles } = useRole();
  const [twoLetterLang, setTwoLetterLang] = useState<string>();

  useEffect(() => {
    setTwoLetterLang(String(lang).substr(0, 2));
  }, [lang]);

  const params: GetDownloadDocsContentParams = {
    lang: twoLetterLang || '',
    type: PolicyContentType.POLICY,
  };

  const { data: content } = useQuery(
    [QueryCacheName.DOWNLOAD_DOCS_CONTENT, params],
    () => getDownloadDocsContent(params),
    {
      enabled: twoLetterLang,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );

  return (
    <>
      <div className="flex flex-col gap-4 pt-4 pb-6">
        <Trans>Documents available for download</Trans>
      </div>
      <PoliciesTable content={content || initContent} />
    </>
  );
};

export default Policies;
